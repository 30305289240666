import { isProduction } from 'shared/env';
export const DEV_EMAIL = 'topshelfwebsolutions@gmail.com';
export const PROD_EMAIL =
  'jhove@transportationenergy.org, topshelfwebsolutions@gmail.com';

export const getEmail = () => {
  if (isProduction()) {
    return PROD_EMAIL;
  }

  return DEV_EMAIL;
};
